
import { Component, Ref, Prop, Vue } from 'vue-property-decorator';
import LmsForm from '@/components/registration/Form.vue';
import LmsInput from '@/components/registration/FieldInput.vue';
import { VERIFICATION_CHANNEL, VERIFICATION_CREATE_STATUS } from '@/api/models/verification';
import { LmsFormState } from '@/components/registration/models/form';
import Method from '@/views/PasswordForgot/Method.vue';
import { Input } from '@/components/registration/models/input';
import PasswordForgotForm from '@/views/PasswordForgot/PasswordForgotForm.vue';
import Container from '@/views/PasswordForgot/Container.vue';
import { FORM_ERRORS } from '@/components/registration/settings/rules';
import { PasswordRecoveryService } from '@/services/passwordRecovery';

type FormPayload = Record<string, string>;

@Component({
  components: { Container, PasswordForgotForm, Method, LmsForm, LmsInput }
})
export default class MethodForm extends Vue {
  @Prop({ required: true }) title!: string;
  @Prop({ required: true }) identifierName!: string;
  @Prop({ required: true }) errorMessage!: FORM_ERRORS;
  @Prop({ required: true }) fields!: Input[];
  @Prop({ required: true }) channel!: VERIFICATION_CHANNEL;
  @Ref() form: LmsFormState;

  loading = false;

  async submit(payload: FormPayload) {
    this.loading = true;

    try {
      PasswordRecoveryService.data.identifier = payload[this.identifierName];
      PasswordRecoveryService.data.channel = this.channel;
      await PasswordRecoveryService.initVerification();
      await this.$router.push({ name: 'PasswordForgotVerification' });
    } catch ({ data, status }) {
      switch (status) {
        case VERIFICATION_CREATE_STATUS.INVALID_IDENTIFIER:
        case VERIFICATION_CREATE_STATUS.VERIFICATION_IDENTITY_NOT_SUPPORTED:
          this.form.setErrors([
            {
              parameters: {},
              propertyPath: this.identifierName,
              title: this.errorMessage
            }
          ]);
      }
    } finally {
      this.loading = false;
    }
  }
}
