
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'Method'
})
export default class Method extends Vue {
  @Prop({ required: true }) title!: string;
  @Prop({ required: true }) message!: string;
  @Prop({ required: true }) icon!: string;
}
