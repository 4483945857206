import { render, staticRenderFns } from "./MethodForm.vue?vue&type=template&id=5edeccfe&scoped=true"
import script from "./MethodForm.vue?vue&type=script&lang=ts"
export * from "./MethodForm.vue?vue&type=script&lang=ts"
import style0 from "./MethodForm.vue?vue&type=style&index=0&id=5edeccfe&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5edeccfe",
  null
  
)

export default component.exports