
import { Component, Ref, Vue } from 'vue-property-decorator';
import LmsForm from '@/components/registration/Form.vue';
import LmsInput from '@/components/registration/FieldInput.vue';
import LmsCheckboxGroup from '@/components/registration/FieldCheckBoxGroup.vue';
import { FORM_ERRORS, numeric, required } from '@/components/registration/settings/rules';
import { Input } from '@/components/registration/models/input';
import { emailVerificationMask } from '@/components/registration/settings/mask';
import { LmsFormState } from '@/components/registration/models/form';
import { VERIFICATION_CONFIRM_STATUS } from '@/api/models/verification';
import CircleTimer from '@/components/CircleTimer.vue';
import PasswordForgotForm from '@/views/PasswordForgot/PasswordForgotForm.vue';
import Container from '@/views/PasswordForgot/Container.vue';
import { PasswordRecoveryService } from '@/services/passwordRecovery';

@Component({
  components: {
    Container,
    PasswordForgotForm,
    CircleTimer,
    LmsForm,
    LmsInput,
    LmsCheckboxGroup
  }
})
export default class PasswordForgotVerification extends Vue {
  @Ref('form') form: LmsFormState;

  fields: Input[] = [
    {
      name: 'passwordResetCode',
      setting: {
        tooltip: false,
        placeholder: false,
        title: true,
        mask: emailVerificationMask
      },
      initial: '',
      autocomplete: 'one-time-code',
      rules: { required, numeric }
    }
  ];

  countDownTime = 0;
  loading = false;

  get subtitleContent() {
    if (PasswordRecoveryService.identifierIsEmail) {
      return this.$t('FORGOT_PASSWORD.VERIFICATION.EMAIL.TITLE');
    }
    if (PasswordRecoveryService.identifierIsPhone) {
      return this.$t('FORGOT_PASSWORD.VERIFICATION.PHONE.TITLE');
    }

    return '';
  }

  get isRepeatActionActive() {
    return !this.loading && this.countDownTime === 0;
  }

  async repeatVerification() {
    if (this.countDownTime || this.loading || !PasswordRecoveryService.verification) {
      return;
    }
    this.loading = true;

    try {
      await PasswordRecoveryService.verification.repeat();
      await this.startTimer();

      this.codeSent();
    } catch (e) {
      return;
    } finally {
      this.loading = false;
    }
  }

  async startTimer() {
    if (PasswordRecoveryService.verification) {
      const timer = await PasswordRecoveryService.verification.getTimer();
      this.countDownTime = timer || 0;
    }
  }

  async submit(payload: Record<string, string>) {
    if (!PasswordRecoveryService.verification) {
      return;
    }
    this.loading = true;
    try {
      await PasswordRecoveryService.verification.confirm(payload.passwordResetCode);
      await this.$router.push({ name: 'PasswordReset' });
    } catch ({ data, status }) {
      if (status === VERIFICATION_CONFIRM_STATUS.INVALID_VERIFICATION_CODE) {
        return this.form.setErrors([
          {
            parameters: {},
            propertyPath: 'passwordResetCode',
            title: FORM_ERRORS.FIELD_ERROR_WRONG_VERIFICATION_CODE
          }
        ]);
      }
      if (status === VERIFICATION_CONFIRM_STATUS.IS_ALREADY_CONFIRMED_OR_EXPIRED) {
        await PasswordRecoveryService.verification.registerVerification();
        await this.startTimer();
        this.codeSent();
        return;
      }
      return this.form.setErrors(data.violations);
    } finally {
      this.loading = false;
    }
  }

  codeSent() {
    if (PasswordRecoveryService.identifierIsEmail) {
      this.$notify.success(this.$t('FORGOT_PASSWORD.VERIFICATION.EMAIL.NEW_CODE_SENT').toString());
    }
    if (PasswordRecoveryService.identifierIsPhone) {
      this.$notify.success(this.$t('FORGOT_PASSWORD.VERIFICATION.PHONE.NEW_CODE_SENT').toString());
    }
  }
}
