
import { Component, Vue } from 'vue-property-decorator';
import { FORM_ERRORS, required } from '@/components/registration/settings/rules';
import MethodForm from '@/views/PasswordForgot/MethodForm.vue';
import { Input } from '@/components/registration/models/input';
import { noSpacePattern } from '@/components/registration/settings/pattern';
import { VERIFICATION_CHANNEL } from '@/api/models/verification';

@Component({
  components: { MethodForm }
})
export default class PasswordForgot extends Vue {
  fields: Input[] = [
    {
      name: 'identifierEmail',
      setting: {
        tooltip: false,
        type: 'email',
        pattern: noSpacePattern
      },
      initial: '',
      autocomplete: 'email',
      rules: { required }
    }
  ];

  channel = VERIFICATION_CHANNEL.MAILER;

  errorMessage = FORM_ERRORS.FIELD_ERROR_WRONG_CLIENT_IDENTIFIER_EMAIL;
}
