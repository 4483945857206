
import { Component, Ref, Vue } from 'vue-property-decorator';
import LmsForm from '@/components/registration/Form.vue';
import LmsInput from '@/components/registration/FieldInput.vue';
import { InputSetting } from '@/components/registration/models/input';
import { FORM_ERRORS, passwordFormat, required } from '@/components/registration/settings/rules';
import { PasswordResetPayload } from '@/views/PasswordForgot/PasswordResetForm';
import PasswordForgotForm from '@/views/PasswordForgot/PasswordForgotForm.vue';
import { LmsFormState } from '@/components/registration/models/form';
import Container from '@/views/PasswordForgot/Container.vue';
import { PasswordRecoveryService } from '@/services/passwordRecovery';
import { APPLICATION_ROUTES } from '@/router/routes_type';

@Component({
  components: { Container, PasswordForgotForm, LmsForm, LmsInput }
})
export default class PasswordReset extends Vue {
  @Ref('form') form: LmsFormState;
  loading = false;
  newPasswordHasSaved = false;

  fieldSettings: InputSetting = {
    mask: 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX',
    type: 'password'
  };

  loginPage = { name: APPLICATION_ROUTES.LOGIN_PAGE };

  fieldRules = { required, passwordFormat };

  fields = [
    {
      name: 'newPassword',
      setting: this.fieldSettings,
      initial: '',
      autocomplete: 'new-password',
      rules: this.fieldRules
    },
    {
      name: 'repeatNewPassword',
      setting: this.fieldSettings,
      initial: '',
      autocomplete: 'new-password',
      rules: this.fieldRules
    }
  ];

  get title() {
    if (this.newPasswordHasSaved) {
      return this.$t('RESET_PASSWORD.TITLE_SAVED').toString();
    }

    return this.$t('RESET_PASSWORD.TITLE').toString();
  }

  async submit(payload: PasswordResetPayload) {
    if (!PasswordRecoveryService.verification) {
      return;
    }

    if (payload.newPassword !== payload.repeatNewPassword) {
      this.form.setErrors([
        {
          parameters: {},
          propertyPath: 'repeatNewPassword',
          title: FORM_ERRORS.FIELD_ERROR_PASSWORD_IS_NOT_SAME
        }
      ]);
      return;
    }

    this.loading = true;
    try {
      await PasswordRecoveryService.passwordReset(payload.newPassword);
      this.newPasswordHasSaved = true;
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }
}
