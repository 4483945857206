import { render, staticRenderFns } from "./Container.vue?vue&type=template&id=10ccfcdf&scoped=true&functional=true"
import script from "./Container.vue?vue&type=script&lang=ts"
export * from "./Container.vue?vue&type=script&lang=ts"
import style0 from "./Container.vue?vue&type=style&index=0&id=10ccfcdf&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "10ccfcdf",
  null
  
)

export default component.exports